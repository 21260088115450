<template>
  <div>
    <div class="content-lu" id="contentLu" style="clear: both">
      <div class="content-lu-title">{{ $t("index.v1.text22") }}</div>
      <div>
        <img
          :style="{ left: imgLeft + 'px' }"
          class="car"
          ref="imgRef"
          src="@/assets/newQJ/index/image/car@2x.png"
          alt=""
        />
      </div>
      <div class="content-lu-lu">
        <img ref="carImg" src="@/assets/newQJ/index/image/lu@2x.png" alt="" />
      </div>
      <div class="content-lu-btn">
        <ul id="lu">
          <li
            v-for="item in list"
            :key="item.id"
            :ref="item.ref"
            @click="setCarLocation(item.id)"
          >
            <span>{{ item.title }}</span>
          </li>
        </ul>
      </div>
      <div class="content-lu-main">
        <div class="content-lu-main-box">
          <div class="content-lu-main-box-aaa" ref="content-lu-main-box">
            <div
              class="content-lu-main-list"
              :style="{
                width: widthLong + 'px',
                left: moveLeft + 'px',
              }"
            >
              <div
                :style="{
                  width: itemWidth + 'px',
                  marginRight: itemMarginRight + 'px',
                }"
                class="content-lu-main-list-item"
                v-for="item in infoList"
                :key="item.id"
              >
                <div class="content-lu-main-list-item-header">
                  {{ item.year }}
                </div>
                <div>
                  <p v-for="item1 in item.list" :key="item1.id">
                    {{ $t(item1.title) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lu",
  data() {
    return {
      list: [
        { id: 0, title: "2011", ref: "one" },
        { id: 1, title: "2014", ref: "two" },
        { id: 2, title: "2017", ref: "three" },
        { id: 3, title: "2020", ref: "four" },
      ],
      imgLeft: 0,
      activeIndex: 0,
      infoList: [
        {
          id: 0,
          year: 2011,
          list: [{ id: 20110, title: "index.v1.text50" }],
        },
        {
          id: 1,
          year: 2013,
          list: [
            { id: 20130, title: "index.v1.text47" },
            { id: 20131, title: "index.v1.text48" },
            { id: 20132, title: "index.v1.text49" },
          ],
        },
        {
          id: 2,
          year: 2014,
          list: [
            { id: 20140, title: "index.v1.text43" },
            { id: 20141, title: "index.v1.text44" },
            { id: 20142, title: "index.v1.text45" },
            { id: 20143, title: "index.v1.text46" },
          ],
        },
        {
          id: 3,
          year: 2016,
          list: [
            { id: 20160, title: "index.v1.text35" },
            { id: 20161, title: "index.v1.text36" },
            { id: 20162, title: "index.v1.text37" },
            { id: 20163, title: "index.v1.text38" },
            { id: 20164, title: "index.v1.text39" },
            { id: 20165, title: "index.v1.text40" },
            { id: 20166, title: "index.v1.text41" },
            { id: 20167, title: "index.v1.text42" },
          ],
        },
        {
          id: 4,
          year: 2017,
          list: [
            { id: 20170, title: "index.v1.text29" },
            { id: 20171, title: "index.v1.text30" },
            { id: 20172, title: "index.v1.text31" },
            { id: 20173, title: "index.v1.text32" },
            { id: 20174, title: "index.v1.text33" },
            { id: 20175, title: "index.v1.text34" },
          ],
        },
        {
          id: 5,
          year: 2019,
          list: [
            { id: 20190, title: "index.v1.text26" },
            { id: 20191, title: "index.v1.text27" },
            { id: 20192, title: "index.v1.text28" },
          ],
        },
        {
          id: 6,
          year: 2020,
          list: [
            { id: 20200, title: "index.v1.text23" },
            { id: 20201, title: "index.v1.text24" },
            { id: 20202, title: "index.v1.text25" },
          ],
        },
      ],
      widthLong: 3500,
      itemWidth: 450,
      itemMarginRight: 50,
      moveLeft: 0,
      refLong: 1550,
    };
  },
  methods: {
    /***
     * 设置自行车位置
     * @param index 移动到第几个年份的下面
     */
    setCarLocation(index) {
      this.activeIndex = index;
      const ref = this.$refs[this.list[index].ref];
      this.imgLeft =
        ref[0].getBoundingClientRect().left +
        (ref[0].offsetWidth - this.$refs.imgRef.offsetWidth) / 2;
      this.moveItem();
    },
    setItemSize() {
      let ref = this.$refs["content-lu-main-box"].offsetWidth;
      this.refLong = ref;
      let ww = ref / 3;
      if (ref < 750) {
        ww = ref / 2;
      }
      if (ref < 650) {
        ww = ref;
      }
      this.widthLong = ww * 8;
      let width = ww * 0.9;
      let marginLeft = ww * 0.1;
      let screenW = window.screen.width;

      if (screenW == 1550 || screenW == 1440) {
        //
        width = ww * 0.8;
        marginLeft = ww * 0.17;
      } else if (screenW == 1280) {
        width = ww * 0.86;
        marginLeft = ww * 0.14;
      }
      this.itemWidth = width;
      this.itemMarginRight = marginLeft * 1.5;
      this.setCarLocation(this.activeIndex);
    },
    moveItem() {
      let index = this.activeIndex + 1;
      if (this.refLong < 750) {
        index = this.activeIndex * 2;
      }
      if (this.refLong < 650) {
        index = this.activeIndex * 2;
      }
      this.moveLeft =
        this.activeIndex === 0
          ? 0
          : -(this.itemWidth + this.itemMarginRight) * index;
    },
  },
  mounted() {
    this.setItemSize();
    window.onresize = () => {
      this.setItemSize();
      //this.handleScreen();
    };
  },
  beforeDestroy() {
    window.onresize = null;
  },
};
</script>

<style scoped>
.content-lu {
  padding-top: 80px;
}
.content-lu-title {
  font-size: 42px;
  font-weight: normal;
  color: #4d4d4d;
  text-align: center;
  margin-bottom: 70px;
  /* font-family: MILanPro; */
}
.content-lu-lu {
  line-height: 0;
}
.content-lu-lu > img {
  width: 100%;
}
.content-lu-btn {
  text-align: center;
}
.content-lu-btn > ul {
  font-size: 18px;
  font-weight: 500;
  color: #837371;
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.content-lu-btn > ul > li {
  display: inline-block;
  width: 100px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  position: relative;
}
.content-lu-btn > ul > li:hover {
  color: #000000;
}
.car {
  width: 54px;
  position: relative;
  left: 500px;
}
.content-lu-btn > ul > li.activeCar > img {
  opacity: 1;
}

.content-lu-main-box {
  overflow-y: auto;
  width: 100%;
  background-color: #fbfbfb;
}

.content-lu-main-list {
  width: 4500px;
  display: flex;
  background-color: #fbfbfb;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  left: 0;
  transition: left 0.3s;
  z-index: 1;
}
.content-lu-main-list-item > div:nth-of-type(2) > p:last-child {
  margin-bottom: 0;
}
.content-lu-main-box-aaa {
  width: 1550px;
  overflow: hidden;
  margin: 0 auto;
}
.content-lu-main-list-item {
  width: 450px;
  display: inline-block;
  margin-right: 100px;
}

.content-lu-main-list-item-header {
  font-size: 26px;
  font-weight: 500;
  color: #837371;
  border-bottom: 2px solid #999999;
  padding-bottom: 7px;
  /* font-family: MILanPro; */
}
.content-lu-main-list-item > div:nth-of-type(2) {
  margin-top: 15px;
  font-size: 16px;
  font-weight: normal;
  color: #666666;
}
.content-lu-main-list-item > div:nth-of-type(2) > p {
  margin-bottom: 15px;
}

.content-product-list > .content-product-list-item > img {
  width: 100%;
}

.content-product-list > .content-product-list-item > div {
  padding-top: 30px;
  padding-bottom: 30px;
}
.content-product-list > .content-product-list-item > div > div {
  font-size: 30px;
  font-weight: normal;
  color: #666666;
  text-align: center;
}
.content-product-list > .content-product-list-item > div > p {
  font-size: 16px;
  font-weight: normal;
  color: #999999;
  margin: 20px 30px 0;
  line-height: 40px;
  word-wrap: break-word;
}

.content-location > div:nth-of-type(2) {
  width: 978px;
  margin: 0 auto;
}
.content-location > div:nth-of-type(2) > img {
  width: 100%;
}

.content-location-list-main-list > li {
  flex: 1;
  line-height: 50px;
  text-align: center;
  border-top: 2px solid #fff;
  cursor: pointer;
  position: relative;
  color: #999999;
}
/* 小于1550 - 1280 像素开始 */
@media screen and (max-width: 1550px) {
  .three-content-computed-item-one-left-item-header > div span {
    font-size: 24px;
    left: 43px;
    top: 37px;
  }

  .three-content-computed-item-one-left-item-header > div img {
    width: 85px;
  }

  .content-computed
    > .content-computed-first
    > .content-computed-first-left
    > .content-computed-first-left-title {
    height: 73px;
    font-size: 38px;
  }
  .content-computed-first-left-list-item > div > div > img {
    width: 80px;
    height: 67px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) {
    font-size: 13px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) > div > div {
    margin-bottom: 10px;
  }
  .content-computed-first-left-list-item > div > div > span {
    font-size: 17px;
  }
  .content-lu {
    padding-top: 62px;
  }

  .content-lu-btn > ul {
    font-size: 14px;
  }
  .content-lu-btn > ul > li > img {
    width: 41px;
    top: -42px;
    left: 50%;
    transform: translateX(-50%);
  }
  .content-lu-btn > ul > li {
    height: 40px;
    line-height: 40px;
  }
  .content-lu-main-list {
    margin-top: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 3150px;
  }
  .content-lu-main-box-aaa {
    width: 1170px;
  }

  .content-lu-main-list-item {
    width: 300px;
    margin-right: 132px;
  }
  .content-lu-main-list-item-header {
    font-size: 19px;
    border-bottom: 1px solid #999999;
    padding-bottom: 4px;
  }
  .content-lu-main-list-item > div:nth-of-type(2) {
    font-size: 14px;
  }

  .content-product-list > .content-product-list-item > div {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 26px;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 14px;
    font-weight: normal;
    color: #999999;
    margin: 20px 27px 0;
    line-height: 33px;
  }

  .new-content-computed-item-left-content > p {
    font-size: 20px;
  }

  .new-content-computed-item .new-img {
    width: 48px;
  }
  .new-content-computed-item-left-item {
    padding: 34px 28px;
  }
  .new-big-img.hh {
  }
  .content-product-list {
    width: 1165px;
  }
  .content-location {
    width: 1165px;
  }
  .three-content-computed-item-one-left-item-center > p {
    font-size: 14px;
    line-height: 32px;
  }
}

@media screen and (max-width: 1440px) {
  .content-product-title {
    font-size: 38px;
    margin-bottom: 38px;
  }
  .content-lu-title {
    font-size: 38px;
    /* font-family: MILanPro; */
  }
  .car {
    width: 41px;
  }
  .three-content-computed-item-one-left-title {
    font-size: 40px;
  }
  .content-location-title {
    font-size: 38px;
    margin-bottom: 38px;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 24px;
  }

  .content-product-list > .content-product-list-item > div > p {
    font-size: 14px;
  }
}
/* 小于 1280 - 960像素 开始 */
@media screen and (max-width: 1280px) {
  .content-computed {
    width: 960px;
  }
  .car {
    width: 41px;
  }
  .three-content-computed {
    width: 960px;
  }
  .three-content-computed-item-one-right {
    width: 626px;
  }
  .three-content-computed-item-one-left-title {
    font-size: 26px;
  }
  .three-content-computed-item-one-left-item-header {
    padding-top: 24px;
  }
  .three-content-computed-item-one-left-item-header > div span {
    font-size: 20px;
    left: 37px;
    top: 31px;
  }
  .content-location-list-main-list {
    font-size: 14px;
  }
  .three-content-computed-item-one-left-item-center {
    margin: 24px 40px;
  }
  .three-content-computed-item-one-left-item-header > div img {
    width: 70px;
  }
  .three-content-computed-item-one-left-item {
    min-height: 265px;
  }

  .content-computed
    > .content-computed-first
    > .content-computed-first-left
    > .content-computed-first-left-title {
    font-size: 26px;
    height: 53px;
  }
  .content-computed-first-left-list-item {
    height: 258px;
    position: relative;
  }
  .content-computed-first-left-list-item > div > div > img {
    width: 54px;
    height: 45px;
  }
  .content-computed-first-left-list-item > div > div > span {
    font-size: 14px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) {
    font-size: 12px;
    width: 156px;
    margin-top: 20px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(1) {
    margin-top: 30px;
  }
  .content-lu-main-list-item-header {
    font-size: 20px;
  }
  .content-lu-title {
    font-size: 26px;
    margin-bottom: 38px;
  }

  .content-lu-main-box-aaa {
    width: 960px;
  }

  .content-lu-main-list-item {
    width: 280px;
    margin-right: 60px;
  }
  .content-lu-main-list {
    margin-top: 0;
  }
  .content-lu-main-list-item > div:nth-of-type(2) > p {
    margin-bottom: 12px;
  }
  .content-lu-main-list {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .content-lu-main-list-item > div:nth-of-type(2) > p:last-child {
    margin-bottom: 0;
  }
  .three-content-computed-item-one-left-item-center > p {
    font-size: 14px;
    line-height: 28px;
  }
  .content-product-title {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .content-product-list > .content-product-list-item {
    width: 215px;
    margin-right: 0;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 20px;
  }
  .content-product-list > .content-product-list-item > div {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 14px;
    font-weight: normal;
    color: #999999;
    margin: 12px 24px 0;
    line-height: 26px;
  }
  .content-location {
    width: 960px;
    padding-top: 60px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) > div > div {
    margin-bottom: 2px;
  }
  .content-location-title {
    font-size: 26px;
    margin-bottom: 22px;
  }
  .content-location-list-main-header {
    font-size: 14px;
  }
  .content-location-list-main-list > li {
    border-top-width: 1px;
  }
  .loId {
    font-size: 14px;
  }

  .new-content-computed-item {
    width: 960px;
  }
  .new-content-computed-item-left-item {
    padding: 21px 18px;
  }
  .new-content-computed-item-left-content > p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .content-product-list {
    width: 960px;
  }
  .content-location > div:nth-of-type(2) {
    width: 90%;
  }
}
/* 小于 1280 - 980 像素 结束 */

/*  750 - 980 像素 结束 */
@media screen and (max-width: 980px) {
  .isPC {
    display: none !important;
  }
  .isPhone {
    display: block;
  }
  .max.isPC {
    display: block !important;
  }
  .min.isPhone {
    display: none;
  }
  .content-computed {
    width: 700px;
  }

  .new-content-computed-item-left-content > p {
    font-size: 14px;
  }
  .content-location {
    width: 700px;
  }
  .content-location > div:nth-of-type(2) {
    width: 700px;
    margin: 0 auto;
  }
  .content-computed-phone {
    width: 700px;
  }
  .content-computed-phone-title {
    font-size: 26px;
    margin-bottom: 32px;
    text-align: center;
  }
  .content-computed-phone-img {
    width: 100%;
  }
  .content-computed-phone-list > div {
    width: 50%;
    display: inline-block;
    height: 535px;
    float: left;
  }
  .content-computed-phone-list-item-header {
    text-align: center;
    padding-top: 47px;
    margin-bottom: 43px;
  }
  .content-computed-phone-list-item-header > div {
    display: inline-block;
    position: relative;
  }
  .content-computed-phone-list-item-header > div > img {
    width: 128px;
    height: 106px;
  }
  .content-computed-phone-list-item-header > div > span {
    font-size: 26px;
    position: absolute;
    right: 0;
    bottom: -4px;
  }
  .content-computed-phone-list-item-content {
    margin: 0 50px;
    font-size: 22px;
    color: #333333;
  }
  .content-computed-phone-list-item-content.white {
    color: #ffffff;
  }
  .content-computed-phone-list-item.white {
    color: #ffffff;
  }
  .content-computed-phone-list-item-content > p {
    margin-bottom: 10px;
  }
  .content-computed-phone-list-item > img {
    width: 100%;
    height: 535px;
  }

  .new-content-computed-phone-list-item {
    width: 700px;
  }
  .new-big-img {
    width: 100%;
  }

  .new-img {
    width: 50px;
  }
  .new-text {
    font-size: 20px;
    position: absolute;
    bottom: -19px;
    left: 31px;
  }
  .new-content-computed-item-left-item {
    padding: 30px 30px;
  }
  .new-content-computed-item-left-item.white {
    color: #ffffff;
  }
  .content-product-list {
    width: 700px;
  }
  .content-lu-main-box-aaa {
    width: 700px;
  }
  .content-lu-main-list-item {
    width: 300px;
    margin-right: 100px;
  }
  .content-product-list > .content-product-list-item {
    width: 165px;
  }
}

/* 750- 540 适配 za*/
@media screen and (max-width: 750px) {
  .isPhone.min {
    display: block !important;
  }
  .isPC.max {
    display: none !important;
  }
  .content-computed {
    width: 100%;
    padding-top: 30px;
  }
  .new-content-computed-phone-list-item {
    width: 100%;
  }
  .content-computed-phone {
    width: 100%;
  }
  .content-computed-phone-title {
    margin-bottom: 30px;
  }

  .content-computed-phone-list-item-header > div > img {
    width: 128rem;
    height: 106rem;
  }
  .content-computed-phone-list-item-header {
    padding-top: 47rem;
    margin-bottom: 43rem;
  }
  .content-computed-phone-list-item-header > div > span {
    font-size: 26rem;
  }
  .content-computed-phone-list > div,
  .content-computed-phone-list-item > img {
    height: 532rem;
  }
  .content-computed-phone-list-item-content {
    margin: 0 30rem;
    font-size: 22rem;
  }
  .content-lu {
    padding-top: 30px;
  }
  .content-lu-title {
    margin-bottom: 50px;
  }
  .content-lu-btn > ul {
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    padding: 0 25px;
  }
  .content-lu-main-list {
    width: 2400px;
    padding-bottom: 40px;
  }
  .content-lu-main-box-aaa {
    width: 90%;
    overflow: auto;
  }
  .content-lu-main-list-item {
    width: 100%;
    display: table-cell;
    margin-right: 1%;
  }
  .content-lu-main-list-item:nth-of-type(1) {
    margin-left: 0;
  }
  .content-lu-main-list-item-header {
    font-size: 20px;
  }
  .content-lu-btn > ul > li > img {
    top: -44px;
  }
  .content-product {
    padding-top: 30px;
    position: relative;
    top: -20px;
    z-index: 2;
    background-color: #fff;
  }
  .content-product-list {
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }
  .content-product-list > .content-product-list-item {
    width: 45%;
    margin: 0 0 3.3% 0;
  }
  .content-product-list > .content-product-list-item > div {
    padding-top: 17.5px;
    min-height: 110px;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 33rem;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 24rem;
    margin: 3px 10px 0;
  }
  .content-location {
    width: 100%;
    background-color: #fbfbfb;
    padding-top: 15px;
  }
  .content-location-title {
    padding-top: 25px;
    font-size: 25px;
    margin-bottom: 35px;
  }
  .isPhone-list {
    padding: 10px;
    font-size: 24rem;
    margin-bottom: 8px;
  }
  .isPhone-list-item {
    border-top: 0.5px solid #eeeeee;
    margin-left: 14px;
  }
  .isPhone-list-item-header {
    display: flex;
    justify-content: space-between;
    padding-top: 19px;
    padding-bottom: 15px;
    font-size: 26rem;
  }
  .isPhone-list-item-header > img {
    width: 15px;
    height: 13px;
  }
  .isPhone-list-item-content {
    font-size: 26rem;
    margin-bottom: 16px;
  }
  .loading {
    width: 350px;
    padding: 0 28px 37px;
  }
  .loading-header {
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 16px;
    margin-top: 35px;
  }
  .loading-content {
    padding-top: 12px;
    margin-bottom: 0;
  }
  .loading-content > p:nth-of-type(1) {
    margin-top: 0;
  }
  .loading-content > p {
    margin-top: 25px;
    font-size: 14px;
    margin-bottom: 26px;
  }
  .loading-content > div {
    margin-top: 25px;
    font-size: 14px;
    margin-bottom: 26px;
  }
  .loading-bottom {
    margin-top: 40px;
  }
  .loading-bottom > div {
    display: block;
    width: 100%;
    margin: 0 0 10px 0;
  }
  .new-text {
    font-size: 16px;
    top: 23px;
  }
  /*::-webkit-scrollbar {*/
  /*    width:12px;*/
  /*}*/
  /*!* 滚动槽 *!*/
  /*::-webkit-scrollbar-track {*/
  /*    -webkit-box-shadow:inset 0 0 4px rgba(0,0,0,0.3);*/
  /*    border-radius:0px;*/
  /*}*/
  /*!* 滚动条滑块 *!*/
  /*::-webkit-scrollbar-thumb {*/
  /*    border-radius:10px;*/
  /*    background:rgba(0,0,0,0.1);*/
  /*}*/
}
</style>
