<template>
  <div>
    <div class="content-location" id="contentLocation">
      <div class="content-location-title">{{ $t(datas.text60) }}</div>
      <div class="max isPC">
        <img
          src="@/assets/newQJ/index/image/location.png"
          img-type="pc"
          lazy-img="@/assets/newQJ/index/image/location.png"
          alt=""
        />
      </div>
      <div class="content-location-list max isPC">
        <div class="content-location-list-main">
          <div class="content-location-list-main-header">
            {{ $t(datas.text61) }}
          </div>
          <ul class="content-location-list-main-list" id="location">
            <li
              data-code="beijing"
              @click="changeLocation(item.id)"
              v-for="item in list"
              :key="item.id"
              :class="activeIndex === item.id ? 'activeLocation' : ''"
            >
              <div>{{ $t(item.title) }}</div>
              <div
                :class="[
                  'content-location-list-main-list-info',
                  activeIndex === item.id ? 'activeLocation' : '',
                ]"
              >
                {{ $t(item.location) }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="min isPhone">
        <div class="isPhone-list">
          <div class="isPhone-list-item" v-for="item in list" :key="item.id">
            <div class="isPhone-list-item-header">
              <span>{{ $t(item.title) }}</span
              ><img
                @click="changeImg(item.id)"
                :src="activeList.includes(item.id) ? up : down"
              />
            </div>
            <div
              class="isPhone-list-item-content"
              :style="{
                display: activeList.includes(item.id) ? 'block' : 'none',
              }"
            >
              <div>
                {{ $t(item.location) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "location",
  data() {
    return {
      up: require("@/assets/newQJ/index/image/xiangshang.png"),
      down: require("@/assets/newQJ/index/image/xiangxia.png"),
      list: [
        {
          id: 0,
          title: "index.v1.text62",
          location: "index.v1.text63",
        },
        {
          id: 1,
          title: "index.v1.text68",
          location: "index.v1.text69",
        },
        {
          id: 2,
          title: "index.v1.text76",
          location: "index.v1.text77",
        },
        {
          id: 3,
          title: "index.v1.text72",
          location: "index.v1.text73",
        },
        {
          id: 4,
          title: "index.v1.text70",
          location: "index.v1.text71",
        },
      ],
      datas: {
        text60: "index.v1.text60",
        text61: "index.v1.text61",
      },
      activeIndex: 0,
      activeList: [],
    };
  },
  methods: {
    changeLocation(id) {
      this.activeIndex = id;
    },
    changeImg(id) {
      if (this.activeList.includes(id)) {
        let index = this.activeList.findIndex((x) => x === id);
        console.log(index, typeof index);
        this.activeList.splice(index, 1);
      } else {
        this.activeList.push(id);
      }
    },
  },
};
</script>

<style scoped>
/* 公用css样式开始*/

.isPhone {
  display: none;
}
.content > .content-sw img,
.content > .content-sw video {
  width: 100%;
}
body {
  min-width: 320px;
}
.activeLocation > div:nth-of-type(1) {
  color: #666666;
}

.content-computed {
  width: 1550px;
  margin: 0 auto 0;
  color: #333333;
  padding-top: 80px;
}
.content-computed > .content-computed-first {
  display: flex;
}
.content-computed > .content-computed-first > .content-computed-first-left {
  flex: 2;
}
.content-computed
  > .content-computed-first
  > .content-computed-first-left
  > .content-computed-first-left-title {
  font-size: 45px;
  font-weight: normal;
  color: #4d4d4d;
  height: 97px;
}

.content-computed
  > .content-computed-first
  > .content-computed-first-left
  > .content-computed-first-left-list {
  display: flex;
}
.content-computed-first-left-list-item {
  flex: 1;
  height: 440px;
}
.content-computed-first-left-list-item > div:nth-of-type(1) {
  text-align: center;
  margin-top: 43px;
}
.content-computed-first-left-list-item > div > div {
  display: inline-block;
  position: relative;
}
.content-computed-first-left-list-item > div > div > img {
  width: 106px;
  height: 88px;
}
.content-computed-first-left-list-item > div > div > span {
  font-size: 21px;
  color: #333333;
  position: absolute;
  right: 0;
  font-weight: normal;
  z-index: 1;
  bottom: -4px;
}
.content-computed-first-left-list-item > div:nth-of-type(2) {
  width: 218px;
  margin: 33px auto 0;
  font-size: 16px;
  font-weight: normal;
  color: #333333;
}

.content-computed-first-left-list-item > div:nth-of-type(2) > div > div {
  margin-bottom: 20px;
}

.content-computed > .content-computed-first > .content-computed-first-right {
  flex: 3;
}
.content-computed
  > .content-computed-first
  > .content-computed-first-right
  > img {
  width: 100%;
}

.content-lu {
  padding-top: 80px;
}
.content-lu-title {
  font-size: 42px;
  font-weight: normal;
  color: #4d4d4d;
  text-align: center;
  margin-bottom: 70px;
  /* font-family: MILanPro; */
}
.content-lu-lu > img {
  width: 100%;
}
.content-lu-btn {
  text-align: center;
}
.content-lu-btn > ul {
  font-size: 18px;
  font-weight: 500;
  color: #837371;
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.content-lu-btn > ul > li {
  display: inline-block;
  width: 100px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  position: relative;
}
.content-lu-btn > ul > li:hover {
  color: #000000;
}
.content-lu-btn > ul > li > img {
  width: 54px;
  position: absolute;
  top: -55px;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
}
.content-lu-btn > ul > li.activeCar > img {
  opacity: 1;
}

.content-lu-main-box {
  overflow-y: auto;
  width: 100%;
  background-color: #fbfbfb;
}

.content-lu-main-list {
  width: 4500px;
  display: flex;
  background-color: #fbfbfb;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  left: 0;
  transition: left 0.3s;
  z-index: 1;
}
.content-lu-main-list-item > div:nth-of-type(2) > p:last-child {
  margin-bottom: 0;
}
.content-lu-main-box-aaa {
  width: 1550px;
  overflow: hidden;
  margin: 0 auto;
}
.content-lu-main-list-item {
  width: 450px;
  display: inline-block;
  margin-right: 100px;
}
.content-location-list-main-list {
  font-size: 16px;
}
.content-lu-main-list-item-header {
  font-size: 26px;
  font-weight: 500;
  color: #837371;
  border-bottom: 2px solid #999999;
  padding-bottom: 7px;
  /* font-family: MILanPro; */
}
.content-lu-main-list-item > div:nth-of-type(2) {
  margin-top: 15px;
  font-size: 16px;
  font-weight: normal;
  color: #666666;
}
.content-lu-main-list-item > div:nth-of-type(2) > p {
  margin-bottom: 15px;
}
.content-product {
  padding-top: 80px;
}
.content-product-title {
  font-size: 42px;
  font-weight: normal;
  color: #4d4d4d;
  text-align: center;
  margin-bottom: 70px;
  /* font-family: MILanPro; */
}
.content-product-list {
  display: flex;
  justify-content: space-between;
  width: 1550px;
  margin: 0 auto;
}
.content-product-list > .content-product-list-item {
  width: 360px;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: 0 4px 4px #eae7db;
  transition: all 0.3s;
}
.content-product-list > .content-product-list-item:hover {
  box-shadow: 0 4px 0 #6f5050;
  transform: scale(1.01);
  background-color: #efefef;
}
.content-product-list > .content-product-list-item:last-child {
  margin-right: 0;
}
.content-product-list > .content-product-list-item > img {
  width: 100%;
}
.content-product-list > .content-product-list-item {
  background: #fafafa;
}
.content-product-list > .content-product-list-item > div {
  padding-top: 30px;
  padding-bottom: 30px;
}
.content-product-list > .content-product-list-item > div > div {
  font-size: 30px;
  font-weight: normal;
  color: #666666;
  text-align: center;
}
.content-product-list > .content-product-list-item > div > p {
  font-size: 16px;
  font-weight: normal;
  color: #999999;
  margin: 20px 30px 0;
  line-height: 40px;
  word-wrap: break-word;
}
.content-location {
  margin: 0 auto 0;
  width: 1550px;
  padding-top: 80px;
}
.content-location-title {
  font-size: 42px;
  font-weight: normal;
  color: #4d4d4d;
  text-align: center;
  margin-bottom: 70px;
  /* font-family: MILanPro; */
}
.content-location > div:nth-of-type(2) {
  width: 978px;
  margin: 0 auto;
}
.content-location > div:nth-of-type(2) > img {
  width: 100%;
}
.content-location-list-main {
  margin-bottom: 20px;
}
.content-location-list-main-header {
  font-size: 16px;
  font-weight: normal;
  color: #999999;
  line-height: 27px;
}
.content-location-list-main-list {
  display: flex;
  margin-top: 10px;
}
.content-location-list-main-list > li {
  flex: 1;
  line-height: 50px;
  text-align: center;
  border-top: 2px solid #fff;
  cursor: pointer;
  position: relative;
  color: #999999;
}
.content-location-list-main-list-info {
  width: 97%;
  position: relative;
  text-align: left;
  line-height: 2;
  opacity: 0;
}
.content-location-list-main-list-info.activeLocation {
  opacity: 1;
}
.content-location-list-main-list > li > div:nth-of-type(1):hover {
  color: #000000;
}
.content-location-list-main-list > li.activeLocation {
  border-top-color: #999999;
}

.loId {
  margin-top: 20px;
  color: #999;
  font-size: 16px;
}

.zeZao {
  position: fixed;
  z-index: 998;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: all 1s;
}
.loading {
  position: fixed;
  width: 782px;
  padding: 0 72px 64px;
  z-index: 999;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  top: 50%;
  transition: all 1s;
  opacity: 1;
  min-width: 320px;
  box-sizing: border-box;
}
.loading.not {
  top: -1000px;
  opacity: 0;
}
.zeZao.not {
  opacity: 0;
  z-index: -999;
}
.loading-header {
  font-size: 30px;
  color: #2a2e2e;
  letter-spacing: 0;
  width: 100%;
  text-align: left;
  margin-top: 64px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e9ecf0;
}
.loading-content {
  font-size: 14px;
  color: #5f6464;
  letter-spacing: 0;
  line-height: 25px;
  padding-top: 24px;
  margin-bottom: 82px;
}
.loading-content > p {
  margin-bottom: 30px;
}
.loading-content > div {
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 82px;
}
.loading-content > div > a {
  color: #4d4d4d;
  text-decoration: revert;
}
.loading-bottom > div {
  float: right;
  width: 200px;
  height: 40px;
  margin-right: 16px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #2a2e2e;
  background: #f2f3f5;
  cursor: pointer;
  transition: all 0.3s;
}
.loading-bottom > div:hover {
  background-color: #999999;
  color: #ffffff;
}

.new-content-computed-title {
  font-size: 45px;
  font-weight: normal;
  color: #4d4d4d;
  text-align: center;
  margin-bottom: 70px;
  /* font-family: MILanPro; */
}
.new-content-computed-item {
  width: 1550px;
  display: flex;
  color: #282828;
  margin: 0 auto;
}
.new-content-computed-item > div {
  flex: 1;
}
.new-content-computed-item .new-img {
  width: 60px;
}
.new-content-computed-item .new-big-img {
  width: 100%;
}
.new-content-computed-item .new-text {
  font-size: 24px;
  font-weight: normal;
  position: absolute;
  bottom: -23px;
  left: 32px;
}

.new-content-computed-item-left-content {
  margin-left: 32px;
}
.new-content-computed-item-left-header {
  margin-bottom: 40px;
  position: relative;
}
.new-content-computed-item-left-item {
  padding: 42px 35px;
}
.new-content-computed-item-right.white,
.new-content-computed-item-left.white {
  color: #ffffff;
}
.new-content-computed-item-left-content > p {
  font-size: 23px;
  /* font-family: MILanPro; */
  font-weight: normal;
  margin-bottom: 15px;
}
.new-content-computed-item-left-content.white > p {
  color: #ffffff;
}

.three-content-computed {
  width: 1550px;
}

.three-content-computed-item-one {
  display: flex;
}
.three-content-computed-item-one-left {
  flex: 1;
}
.three-content-computed-item-one-left-title {
  font-size: 42px;
  /* font-family: MILanPro; */
  font-weight: 400;
  color: #4d4d4d;
  margin-bottom: 39px;
}
.three-content-computed-item-one-left-item {
  min-height: 346px;
  background-color: #f6f6f8;
}
.three-content-computed-item-one-right {
  width: 939px;
}
.three-content-computed-item-one-right > img {
  width: 100%;
  min-height: 100%;
}
.three-content-computed-item-one-left-item-header {
  text-align: center;
  padding-top: 30px;
}
.three-content-computed-item-one-left-item-header > div {
  position: relative;
  display: inline-block;
}
.three-content-computed-item-one-left-item-header > div img {
  width: 105px;
}
.three-content-computed-item-one-left-item-header > div span {
  font-size: 30px;
  font-weight: normal;
  color: #333333;
  position: absolute;
  left: 54px;
  top: 46px;
  width: 200%;
  text-align: left;
}
.three-content-computed-item-one-left-item-center {
  margin: 30px 50px;
}
.three-content-computed-item-one-left-item-center > p {
  font-size: 16px;
  /* font-family: MILanPro; */
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.ggg {
  width: 18px;
}
.three-content-computed-item-two {
  display: flex;
  margin-top: 18px;
}
.three-content-computed-item-two-item {
  flex: 1;
}
.three-content-computed-item-two-item.w25 {
  flex: 25;
}
.three-content-computed-item-two-item.w44 {
  flex: 44;
}
.three-content-computed-item-two-item.w31 {
  flex: 31;
}
.three-content-computed-item-two-item > img {
  width: 100%;
  min-height: 100%;
}
.three-content-computed-item-one-left-item-header > div span.white {
  color: white;
}
.three-content-computed-item-one-left-item-center.white > p {
  color: #ffffff;
}
/* 公用css样式结束 */

/* 小于1550 - 1280 像素开始 */
@media screen and (max-width: 1550px) {
  .content-computed {
    width: 1165px;
    padding-top: 62px;
  }
  .three-content-computed {
    width: 1165px;
  }
  .three-content-computed-item-one-right {
    width: 758px;
  }

  .three-content-computed-item-one-left-item-header > div span {
    font-size: 24px;
    left: 43px;
    top: 37px;
  }
  .three-content-computed-item-one-left-item-center {
    margin: 28px 40px;
  }
  .three-content-computed-item-one-left-item-header > div img {
    width: 85px;
  }
  .three-content-computed-item-one-left-item-header {
    padding-top: 28px;
  }
  .three-content-computed-item-one-left-item {
    min-height: 265px;
  }
  .content-computed-first-left-list-item {
    height: 330px;
  }
  .content-computed
    > .content-computed-first
    > .content-computed-first-left
    > .content-computed-first-left-title {
    height: 73px;
    font-size: 38px;
  }
  .content-computed-first-left-list-item > div > div > img {
    width: 80px;
    height: 67px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) {
    font-size: 13px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) > div > div {
    margin-bottom: 10px;
  }
  .content-computed-first-left-list-item > div > div > span {
    font-size: 17px;
  }
  .content-lu {
    padding-top: 60px;
  }

  .content-lu-btn > ul {
    font-size: 14px;
  }
  .content-lu-btn > ul > li > img {
    width: 41px;
    top: -42px;
    left: 50%;
    transform: translateX(-50%);
  }
  .content-lu-btn > ul > li {
    height: 40px;
    line-height: 40px;
  }
  .content-lu-main-list {
    margin-top: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 3150px;
  }
  .content-lu-main-box-aaa {
    width: 1165px;
  }

  .content-lu-main-list-item {
    width: 300px;
    margin-right: 132px;
  }
  .content-lu-main-list-item-header {
    font-size: 19px;
    border-bottom: 1px solid #999999;
    padding-bottom: 4px;
  }
  .content-lu-main-list-item > div:nth-of-type(2) {
    font-size: 14px;
  }
  .content-location-list-main-list {
    font-size: 14px;
  }
  .content-product {
    padding-top: 62px;
  }

  .content-product-list > .content-product-list-item {
    width: 275px;
  }
  .content-product-list > .content-product-list-item > div {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 26px;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 14px;
    font-weight: normal;
    color: #999999;
    margin: 20px 27px 0;
    line-height: 33px;
  }

  .new-content-computed-item {
    width: 1165px;
  }
  .new-content-computed-item-left-content > p {
    font-size: 20px;
  }
  .new-content-computed-item .new-text {
    font-size: 18px;
    left: 28px;
  }
  .new-content-computed-item .new-img {
    width: 48px;
  }
  .new-content-computed-item-left-item {
    padding: 34px 28px;
  }
  .new-big-img.hh {
  }
  .content-product-list {
    width: 1165px;
  }
  .content-location {
    width: 1165px;
  }
  .three-content-computed-item-one-left-item-center > p {
    font-size: 14px;
    line-height: 32px;
  }
}

@media screen and (max-width: 1440px) {
  .content-product-title {
    font-size: 38px;
    margin-bottom: 38px;
  }
  .content-lu-title {
    font-size: 38px;
    /* font-family: MILanPro; */
  }
  .three-content-computed-item-one-left-title {
    font-size: 40px;
  }
  .content-location-title {
    font-size: 38px;
    margin-bottom: 38px;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 24px;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 14px;
  }
  .content-location-list-main-list {
    font-size: 16px;
  }
}
/* 小于 1280 - 960像素 开始 */
@media screen and (max-width: 1280px) {
  .content-computed {
    width: 960px;
  }
  .three-content-computed {
    width: 960px;
  }
  .three-content-computed-item-one-right {
    width: 626px;
  }
  .three-content-computed-item-one-left-title {
    font-size: 26px;
  }
  .three-content-computed-item-one-left-item-header {
    padding-top: 24px;
  }
  .three-content-computed-item-one-left-item-header > div span {
    font-size: 20px;
    left: 37px;
    top: 31px;
  }
  .content-location-list-main-list {
    font-size: 14px;
  }
  .three-content-computed-item-one-left-item-center {
    margin: 24px 40px;
  }
  .three-content-computed-item-one-left-item-header > div img {
    width: 70px;
  }
  .three-content-computed-item-one-left-item {
    min-height: 265px;
  }

  .content-computed
    > .content-computed-first
    > .content-computed-first-left
    > .content-computed-first-left-title {
    font-size: 26px;
    height: 53px;
  }
  .content-computed-first-left-list-item {
    height: 258px;
    position: relative;
  }
  .content-computed-first-left-list-item > div > div > img {
    width: 54px;
    height: 45px;
  }
  .content-computed-first-left-list-item > div > div > span {
    font-size: 14px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) {
    font-size: 12px;
    width: 156px;
    margin-top: 20px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(1) {
    margin-top: 30px;
  }
  .content-lu-main-list-item-header {
    font-size: 20px;
  }
  .content-lu {
    padding-top: 40px;
  }
  .content-lu-title {
    font-size: 26px;
    margin-bottom: 38px;
  }

  .content-lu-main-box-aaa {
    width: 960px;
  }

  .content-lu-main-list-item {
    width: 280px;
    margin-right: 60px;
  }
  .content-lu-main-list {
    margin-top: 0px;
  }
  .content-lu-main-list-item > div:nth-of-type(2) > p {
    margin-bottom: 12px;
  }
  .content-lu-main-list {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .content-lu-main-list-item > div:nth-of-type(2) > p:last-child {
    margin-bottom: 0;
  }
  .three-content-computed-item-one-left-item-center > p {
    font-size: 14px;
    line-height: 28px;
  }
  .content-product-title {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .content-product-list > .content-product-list-item {
    width: 215px;
    margin-right: 0;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 20px;
  }
  .content-product-list > .content-product-list-item > div {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 14px;
    font-weight: normal;
    color: #999999;
    margin: 12px 24px 0;
    line-height: 26px;
  }
  .content-location {
    width: 960px;
    padding-top: 60px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) > div > div {
    margin-bottom: 2px;
  }
  .content-location-title {
    font-size: 26px;
    margin-bottom: 22px;
  }
  .content-location-list-main-header {
    font-size: 14px;
  }
  .content-location-list-main-list > li {
    border-top-width: 1px;
  }
  .loId {
    font-size: 14px;
  }

  .new-content-computed-item {
    width: 960px;
  }
  .new-content-computed-item-left-item {
    padding: 21px 18px;
  }
  .new-content-computed-item-left-content > p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .content-product-list {
    width: 960px;
  }
  .content-location > div:nth-of-type(2) {
    width: 90%;
  }
}
/* 小于 1280 - 980 像素 结束 */

/*  750 - 980 像素 结束 */
@media screen and (max-width: 980px) {
  .isPC {
    display: none !important;
  }
  .isPhone {
    display: block;
  }
  .max.isPC {
    display: block !important;
  }
  .min.isPhone {
    display: none;
  }
  .content-computed {
    width: 700px;
  }

  .new-content-computed-item-left-content > p {
    font-size: 14px;
  }
  .content-location {
    width: 700px;
  }
  .content-location > div:nth-of-type(2) {
    width: 700px;
    margin: 0 auto;
  }
  .content-computed-phone {
    width: 700px;
  }
  .content-computed-phone-title {
    font-size: 26px;
    margin-bottom: 32px;
    text-align: center;
  }
  .content-computed-phone-img {
    width: 100%;
  }
  .content-computed-phone-list > div {
    width: 50%;
    display: inline-block;
    height: 535px;
    float: left;
  }
  .content-computed-phone-list-item-header {
    text-align: center;
    padding-top: 47px;
    margin-bottom: 43px;
  }
  .content-computed-phone-list-item-header > div {
    display: inline-block;
    position: relative;
  }
  .content-computed-phone-list-item-header > div > img {
    width: 128px;
    height: 106px;
  }
  .content-computed-phone-list-item-header > div > span {
    font-size: 26px;
    position: absolute;
    right: 0;
    bottom: -4px;
  }
  .content-computed-phone-list-item-content {
    margin: 0 50px;
    font-size: 22px;
    color: #333333;
  }
  .content-computed-phone-list-item-content.white {
    color: #ffffff;
  }
  .content-computed-phone-list-item.white {
    color: #ffffff;
  }
  .content-computed-phone-list-item-content > p {
    margin-bottom: 10px;
  }
  .content-computed-phone-list-item > img {
    width: 100%;
    height: 535px;
  }

  .new-content-computed-phone-list-item {
    width: 700px;
  }
  .new-big-img {
    width: 100%;
  }

  .new-img {
    width: 50px;
  }
  .new-text {
    font-size: 20px;
    position: absolute;
    bottom: -19px;
    left: 31px;
  }
  .new-content-computed-item-left-item {
    padding: 30px 30px;
  }
  .new-content-computed-item-left-item.white {
    color: #ffffff;
  }
  .content-product-list {
    width: 700px;
  }
  .content-lu-main-box-aaa {
    width: 700px;
  }
  .content-lu-main-list-item {
    width: 300px;
    margin-right: 100px;
  }
  .content-product-list > .content-product-list-item {
    width: 165px;
  }
}

/* 750- 540 适配 za*/
@media screen and (max-width: 750px) {
  .isPhone.min {
    display: block !important;
  }
  .isPC.max {
    display: none !important;
  }
  .content-computed {
    width: 100%;
    padding-top: 30px;
  }
  .new-content-computed-phone-list-item {
    width: 100%;
  }
  .content-computed-phone {
    width: 100%;
  }
  .content-computed-phone-title {
    margin-bottom: 30px;
  }

  .content-computed-phone-list-item-header > div > img {
    width: 128rem;
    height: 106rem;
  }
  .content-computed-phone-list-item-header {
    padding-top: 47rem;
    margin-bottom: 43rem;
  }
  .content-computed-phone-list-item-header > div > span {
    font-size: 26rem;
  }
  .content-computed-phone-list > div,
  .content-computed-phone-list-item > img {
    height: 532rem;
  }
  .content-computed-phone-list-item-content {
    margin: 0 30rem;
    font-size: 22rem;
  }
  .content-lu {
    padding-top: 30px;
  }
  .content-lu-title {
    margin-bottom: 50px;
  }
  .content-lu-btn > ul {
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    padding: 0 25px;
  }
  .content-lu-main-list {
    width: 2400px;
    padding-bottom: 40px;
  }
  .content-lu-main-box-aaa {
    width: 90%;
    overflow: auto;
  }
  .content-lu-main-list-item {
    width: 100%;
    display: table-cell;
    margin-right: 1%;
  }
  .content-lu-main-list-item:nth-of-type(1) {
    margin-left: 0;
  }
  .content-lu-main-list-item-header {
    font-size: 20px;
  }
  .content-lu-btn > ul > li > img {
    top: -44px;
  }
  .content-product {
    padding-top: 30px;
    position: relative;
    top: -20px;
    z-index: 2;
    background-color: #fff;
  }
  .content-product-list {
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }
  .content-product-list > .content-product-list-item {
    width: 45%;
    margin: 0 0 3.3% 0;
  }
  .content-product-list > .content-product-list-item > div {
    padding-top: 17.5px;
    min-height: 110px;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 33rem;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 24rem;
    margin: 3px 10px 0;
  }
  .content-location {
    width: 100%;
    background-color: #fbfbfb;
    padding-top: 15px;
  }
  .content-location-title {
    padding-top: 25px;
    font-size: 25px;
    margin-bottom: 35px;
  }
  .isPhone-list {
    padding: 10px;
    margin-bottom: 8px;
  }
  .isPhone-list-item {
    border-top: 0.5px solid #eeeeee;
    margin-left: 14px;
  }
  .isPhone-list-item-header {
    display: flex;
    justify-content: space-between;
    padding-top: 19px;
    padding-bottom: 15px;
  }
  .isPhone-list-item-header > img {
    width: 15px;
    height: 13px;
  }
  .isPhone-list-item-content {
    margin-bottom: 16px;
  }
  .loading {
    width: 350px;
    padding: 0 28px 37px;
  }
  .loading-header {
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 16px;
    margin-top: 35px;
  }
  .loading-content {
    padding-top: 12px;
    margin-bottom: 0;
  }
  .loading-content > p:nth-of-type(1) {
    margin-top: 0;
  }
  .loading-content > p {
    margin-top: 25px;
    font-size: 14px;
    margin-bottom: 26px;
  }
  .loading-content > div {
    margin-top: 25px;
    font-size: 14px;
    margin-bottom: 26px;
  }
  .loading-bottom {
    margin-top: 40px;
  }
  .loading-bottom > div {
    display: block;
    width: 100%;
    margin: 0 0 10px 0;
  }
  .new-text {
    font-size: 16px;
    top: 23px;
  }
  /*::-webkit-scrollbar {*/
  /*    width:12px;*/
  /*}*/
  /*!* 滚动槽 *!*/
  /*::-webkit-scrollbar-track {*/
  /*    -webkit-box-shadow:inset 0 0 4px rgba(0,0,0,0.3);*/
  /*    border-radius:0px;*/
  /*}*/
  /*!* 滚动条滑块 *!*/
  /*::-webkit-scrollbar-thumb {*/
  /*    border-radius:10px;*/
  /*    background:rgba(0,0,0,0.1);*/
  /*}*/
}
</style>
